import React from 'react'

import Script from 'dangerous-html/react'
import { Player } from '@lottiefiles/react-lottie-player'
import { Helmet } from 'react-helmet'

import MobileNavigation from '../components/mobile-navigation'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Discover Software Optimization with HintEd Pulse</title>
        <meta
          name="description"
          content="Unlock the full potential of your software with HintEd Pulse. Gain valuable insights to optimize your software usage"
        />
        <meta
          property="og:title"
          content="Discover Software Optimization with HintEd Pulse"
        />
        <meta
          property="og:description"
          content="Unlock the full potential of your software with HintEd Pulse. Gain valuable insights to optimize your software usage"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/a3e8e137-36c0-4dd9-be5f-992ee534f9a4/e7b8b6bb-4891-4401-9c42-476a00efcfbf?org_if_sml=1"
        />
      </Helmet>
      <section className="home-hero">
        <div className="home-menu">
          <MobileNavigation></MobileNavigation>
          <div className="home-desktop-navigation">
            <nav className="home-centered">
              <div className="home-left">
                <img
                  alt="pastedImage"
                  src="/logos/pulse-logo.svg"
                  loading="lazy"
                  className="home-logo"
                />
                <div className="home-links">
                  <a href="#about" className="home-link Link">
                    About
                  </a>
                  <a href="#howitworks" className="home-link1 Link">
                    How it works
                  </a>
                  <a href="#benefits" className="home-link2 Link">
                    <span>Why Us</span>
                    <br></br>
                  </a>
                </div>
              </div>
              <div className="home-right">
                <span className="home-sign-in Link">Sign in</span>
                <div className="home-get-started actions">
                  <span className="home-text02">Get started</span>
                </div>
                <div id="open-mobile-menu" className="home-burger-menu">
                  <img
                    alt="pastedImage"
                    src="/pastedimage-yxbd.svg"
                    className="home-mobile-menu-button"
                  />
                </div>
              </div>
            </nav>
          </div>
          <div>
            <Script
              html={`<script>
      /*
  Mobile menu - Code Embed
  */

  /* listenForUrlChangesMobileMenu() makes sure that if you changes pages inside your app,
  the mobile menu will still work*/

  const listenForUrlChangesMobileMenu = () => {
      let url = location.href;
      document.body.addEventListener("click", () => {
          requestAnimationFrame(() => {
              if (url !== location.href) {
                  runMobileMenuCodeEmbed();
                  url = location.href;
              }
          });
      },
      true
      );
  };

  const runMobileMenuCodeEmbed = () => {
      // Mobile menu
      const mobileMenu = document.querySelector("#mobile-menu")

      // Buttons
      const closeButton = document.querySelector("#close-mobile-menu")
      const openButton = document.querySelector("#open-mobile-menu")
      const navLinks = document.getElementsByClassName("nav-link")



      // On openButton click, set the mobileMenu position left to -100vw
      openButton && openButton.addEventListener("click", function() {
          mobileMenu.style.transform = "translateX(0%)"
      })

      // On closeButton click, set the mobileMenu position to 0vw
      closeButton && closeButton.addEventListener("click", function() {
          mobileMenu.style.transform = "translateX(100%)"
      })

      // On navlink click, set the mobileMenu position to 0vw
      for (const navLink of navLinks) {
  navLink.addEventListener('click', function handleClick(event) {
    console.log('link clicked', event);
    mobileMenu.style.transform = "translateX(100%)"
  });
}
  }

  runMobileMenuCodeEmbed()
  listenForUrlChangesMobileMenu()
</script>
`}
            ></Script>
          </div>
        </div>
        <header className="home-header">
          <h1 className="home-text03">
            <span>Unlock Your Software&apos;s Full Potential</span>
            <br></br>
          </h1>
          <h2 className="home-text06">
            Boost Efficiency and Save Costs with HintEd PULSE
          </h2>
          <div id="contact" className="home-get-started1 actions">
            <span className="home-text07">Get started</span>
          </div>
        </header>
        <div className="home-dashboard-preview">
          <div className="home-outline">
            <img
              alt="Pulse. Empower your software strategy"
              src="/frame%201-1000h.jpg"
              loading="lazy"
              className="home-image"
            />
          </div>
        </div>
      </section>
      <section className="home-features">
        <div className="home-intro">
          <h3 className="home-text08">
            <span>
              Are you making the most of your software licenses?
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
          </h3>
          <span className="home-text11">
            <span>
              Surprisingly, studies show that up to 50% of enterprise software
              licenses go unused, leading to significant financial waste.
              Inefficient software usage not only hampers productivity but also
              squanders valuable resources that could be reinvested into your
              business&apos;s growth.
            </span>
            <br></br>
          </span>
        </div>
        <div id="about" className="home-title">
          <span className="home-text14">
            Your Gateway to Unleashing the Full Potential of Your Software
          </span>
          <span className="home-text15">
            Gain early access to our revolutionary usage analytics and
            actionable insights platform, putting an end to underutilized
            software and embracing a new era of efficiency and cost savings.
          </span>
        </div>
        <div className="home-cards">
          <div className="home-container01">
            <div className="card">
              <img
                alt="pastedImage"
                src="/icon-track-200h.png"
                className="home-icon"
              />
              <span className="home-text16">
                Real-time usage tracking and analysis
              </span>
              <span className="home-text17">
                Gain instant visibility into software usage across your
                organization
              </span>
            </div>
            <div className="home-publish card">
              <img
                alt="pastedImage"
                src="/shelfware-icon-200h.png"
                className="home-icon1"
              />
              <span className="home-text18">
                Identifying unused licenses and shelfware
              </span>
              <span className="home-text19">
                Uncover dormant licenses and eliminate wasteful spending
              </span>
            </div>
          </div>
          <div className="home-container02">
            <div className="card home-analyze">
              <img
                alt="pastedImage"
                src="/icon-data1-200h.png"
                className="home-icon2"
              />
              <span className="home-text20">
                Data visualization and customizable reports
              </span>
              <span className="home-text21">
                Get clear, actionable insights through interactive charts and
                custom reports
              </span>
            </div>
            <div className="card">
              <img
                alt="pastedImage"
                src="/icon-adoption-200h.png"
                className="home-icon3"
              />
              <span className="home-text22">
                User adoption insights and engagement metrics
              </span>
              <span className="home-text23">
                Understand user behavior and boost software adoption
              </span>
            </div>
          </div>
        </div>
      </section>
      <section id="benefits" className="home-statistics">
        <div className="home-stats">
          <div className="home-stat">
            <h1 className="home-text24">
              <span>30%</span>
              <br></br>
            </h1>
            <span className="home-text27">
              increase in software utilization and ROI
            </span>
            <span className="home-text28">
              Utilize licenses effectively, maximizing your return on investment
            </span>
          </div>
          <div className="home-stat1">
            <h1 className="home-text29">25%</h1>
            <span className="home-text30">
              improvement in productivity and efficiency
            </span>
            <span className="home-text31">
              Identify bottlenecks and streamline processes for enhanced
              efficiency
            </span>
          </div>
          <div className="home-stat2">
            <h1 className="home-text32">15</h1>
            <span className="home-text33">
              saved hours per month on software license management
            </span>
            <span className="home-text34">
              Simplify tracking and renewal processes with automated insights
            </span>
          </div>
        </div>
      </section>
      <section id="howitworks" className="home-how-it-works">
        <div className="home-centered-container">
          <div className="home-heading">
            <span className="home-text35">How it works</span>
            <span className="home-text36 title">
              Empowering Your Software Strategy
            </span>
          </div>
          <div className="home-category">
            <div className="home-headng">
              <span className="home-text37">
                1 — Enroll in the BETA
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text38">
                Sign up for exclusive access and receive your login credentials
              </span>
              <div className="home-get-started2 template-button actions">
                <span className="home-text39">Get started</span>
              </div>
            </div>
            <div className="home-container03">
              <Player
                src="https://lottie.host/c55bad1c-6e44-4f70-b723-931b70e1078b/ESn5mOUKsm.json"
                speed="1"
                autoplay
                background="transparent"
                loop
                className="home-lottie-node"
              ></Player>
            </div>
          </div>
          <div className="home-row">
            <div className="home-category1">
              <div className="home-headng1">
                <span className="home-text40">2 — Track Software Usage</span>
                <span className="home-text41">
                  Install Pulse lightweight agent to monitor software usage in
                  real-time
                </span>
              </div>
              <img
                alt="Track Software Usage"
                src="/frame%2086-1200w.png"
                loading="lazy"
                className="home-pasted-image"
              />
            </div>
            <div className="home-category2">
              <div className="home-headng2">
                <span className="home-text42">3 — Analyze Data</span>
                <span className="home-text43">
                  Our powerful algorithms analyze usage data and generate
                  insightful reports
                </span>
              </div>
              <img
                alt="Analyze Data"
                src="/group%203-1200w.png"
                loading="lazy"
                className="home-pasted-image1"
              />
            </div>
          </div>
          <div className="home-row1">
            <div className="home-category3">
              <div className="home-headng3">
                <span className="home-text44">4 — Discover Insights</span>
                <span className="home-text45">
                  Uncover unused licenses, optimize software allocation, and
                  identify user engagement patterns
                </span>
              </div>
              <img
                alt="Discover Insights"
                src="/frame%2085-1200w.png"
                loading="lazy"
                className="home-pasted-image2"
              />
            </div>
            <div className="home-category4">
              <div className="home-headng4">
                <span className="home-text46">5 — Take Action</span>
                <span className="home-text47">
                  Receive actionable recommendations to boost software
                  efficiency and streamline usage
                </span>
              </div>
              <img
                alt="Take Action"
                src="/group%204-1200w.png"
                loading="lazy"
                className="home-pasted-image3"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="home-action-bar">
        <div className="home-action">
          <div className="home-heading1">
            <span className="home-text48 title">
              Unleash the Potential of Your Software
            </span>
            <span className="home-text49">
              Claim Your Exclusive BETA Access Today!
            </span>
            <div className="home-get-started3 template-button actions">
              <span className="home-text50">
                <span>Start free</span>
                <br></br>
              </span>
            </div>
          </div>
          <div className="home-images">
            <img
              alt="image"
              src="/frame%2024-1200w.png"
              loading="lazy"
              className="home-image1"
            />
          </div>
        </div>
      </section>
      <footer className="home-footer">
        <div className="home-content">
          <main className="home-main-content">
            <div className="home-content1">
              <header className="home-main">
                <a
                  href="https://hinted.me"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link3"
                >
                  <div className="home-header1">
                    <img
                      alt="image"
                      src="/logos/pulse%20white.svg"
                      loading="lazy"
                      className="home-branding"
                    />
                    <span className="home-text53">
                      The Digital Adoption Platform
                    </span>
                  </div>
                </a>
                <div className="home-socials">
                  <a
                    href="https://www.linkedin.com/company/hintedme/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link4"
                  >
                    <img
                      alt="image"
                      src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/a3e8e137-36c0-4dd9-be5f-992ee534f9a4/a3c61255-0c84-4de6-818e-15371b360966?org_if_sml=1180"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/hinted.me/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link5"
                  >
                    <img
                      alt="image"
                      src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/a3e8e137-36c0-4dd9-be5f-992ee534f9a4/a08c52a3-a16f-4db9-8559-27bd83c2413d?org_if_sml=1268"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://twitter.com/ed_hint"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link6"
                  >
                    <img
                      alt="image"
                      src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/a3e8e137-36c0-4dd9-be5f-992ee534f9a4/ff591fbb-02fe-4146-b69c-63fffbcdd7e5?org_if_sml=1214"
                      className="social"
                    />
                  </a>
                </div>
              </header>
              <div className="home-container04">
                <div className="home-category5">
                  <div className="home-header2">
                    <span className="footer-header">Solutions</span>
                  </div>
                  <div className="home-links1">
                    <div className="home-container05">
                      <a
                        href="https://hinted.me/digital-adoption-platform/"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="footer-link"
                      >
                        Digital Adoption
                      </a>
                    </div>
                    <div className="home-container06">
                      <a
                        href="https://hinted.me/user-onboarding/"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="footer-link"
                      >
                        User Onboarding
                      </a>
                    </div>
                    <div className="home-container07">
                      <a
                        href="https://hinted.me/employee-training/"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="footer-link"
                      >
                        Employee Training
                      </a>
                    </div>
                  </div>
                </div>
                <div className="home-category6">
                  <div className="home-header3">
                    <span className="footer-header">Company</span>
                  </div>
                  <div className="home-links2">
                    <div className="home-container08">
                      <a
                        href="https://hinted.me/our-vision/"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="home-text59 footer-link"
                      >
                        About
                      </a>
                    </div>
                    <div className="home-container09">
                      <a
                        href="https://hinted.freshteam.com/jobs"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="home-text60 footer-link"
                      >
                        Careers
                      </a>
                    </div>
                    <div className="home-container10">
                      <a
                        href="https://hinted.me/privacy-policy/"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="home-text61 footer-link"
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className="home-copyright">
              <span className="home-text62">
                © 2023 HintEd Company. All Rights Reserved.
              </span>
            </section>
          </main>
          <main className="home-subscribe"></main>
          <section className="home-copyright1">
            <span className="home-text63">
              © 2023 HintEd Company. All Rights Reserved.
            </span>
          </section>
        </div>
      </footer>
      <div>
        <Script
          html={`<style>
.cd-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(94, 110, 141, 0.9);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  overflow-y: auto;
  z-index: 10000;
}

.cd-popup.is-visible {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}

.cd-popup-container {
  overflow-x: hidden;
  border: none;
  position: relative;
  width: 82% !important;
  height: 95% !important;
  max-width: 82% !important;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background: #fff;
  border-radius: .25em .25em .4em .4em;
  text-align: center;
  box-shadow: none;
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  -o-transform: translateY(-40px);
  transform: translateY(-40px);
  /* Force Hardware Acceleration in WebKit */
  -webkit-backface-visibility: hidden;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.cd-popup-container p {
  margin: 0;
  padding: 3em 1em;
  padding-top: 1em;
}

.cd-popup-container .cd-popup-close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
}

.cd-close-button {
  color: #545454;
  border-bottom: none;
}

.cd-popup-container .cd-popup-close::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 8px;
}

.cd-popup-container .cd-popup-close::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 8px;
}

.is-visible .cd-popup-container {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

@media only screen and (min-width: 1170px) {
  .cd-popup-container {
    margin: 2em auto;
  }
}
</style>

<div class="cd-popup contact" role="alert">
  
    <div class="cd-popup-container" style="">
      <p style="">
        <a href="" class="cd-popup-close cd-close-button">
          <i class="fa fa-times" style="pointer-events:none;"></i>
        </a>
      </p>
      <h1 style = "color: #693acf">Schedule a Personalized Demo and Secure Your Spot in the Beta Program Today!</h1>
      <!-- Start of Meetings Embed Script -->
    <div class="meetings-iframe-container" data-src="https://meetings.hubspot.com/mark-maksimov/pulse-landing?embed=true"></div>
    <script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></script>
  <!-- End of Meetings Embed Script -->
      </div> 
</div>

<script>
    // Function to close the form
    function closeForm() {
      document.querySelector(".cd-popup").classList.remove("is-visible");
    }

    // Get all elements with the "actions" class and attach click event listeners
    var actionsElements = document.querySelectorAll(".actions");
    actionsElements.forEach(function (element) {
      element.addEventListener("click", function (event) {
        event.preventDefault();
        document.querySelector(".contact").classList.add("is-visible");
        
      });
    });

    // Close popup when clicking x or off popup
    document.querySelector(".cd-popup").addEventListener("click", function (event) {
      if (event.target.classList.contains("cd-popup-close") || event.target.classList.contains("cd-popup")) {
        event.preventDefault();
        closeForm();
      }
    });

    // Close popup when clicking the esc keyboard button
    document.addEventListener("keyup", function (event) {
      if (event.which == 27) {
        closeForm();
      }
    });

    
  </script>





`}
        ></Script>
      </div>
    </div>
  )
}

export default Home
